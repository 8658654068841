<template>
  <Ranking />
</template>

<script>
import Ranking from "@/components/Ranking/Ranking";
export default {
  components: {
    Ranking
  }
};
</script>
