<template>
  <v-card flat class="mx-auto">
    <v-list v-if="membersWithCount.length > 0">
      <v-list-item-group v-for="(m, i) in membersWithCount" :key="i">
        <v-list-item>
          <v-list-item-avatar>
            <Avatar :user="m" :size="35" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title
              v-text="`${m.rank}. ${m.Login}`"
            ></v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-list-item-action-text class="title">
              {{ m.score }}<v-icon small color="blue">mdi-cards-diamond</v-icon>
              points
            </v-list-item-action-text>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
      </v-list-item-group>
    </v-list>
    <v-row v-else>
      <v-col cols="12" v-for="i in 10" :key="i">
        <v-skeleton-loader
          class="mx-auto"
          type="list-item-avatar"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { db } from "@/plugins/firebase";
import Avatar from "@/components/Profile/Avatar";

export default {
  fiery: true,
  components: { Avatar },
  data: function() {
    return {
      members: this.$fiery(db.collection("Attendance"), {
        key: "id",
        query: (member) => member,
        sub: {
          EventsAttended: {
            query: (events) => events,
            key: "id",
          },
        },
      }),
    };
  },
  computed: {
    membersWithCount() {
      const members = this.members
        .map((m) => {
          m.score = m.EventsAttended.reduce(
            (a, b) => a + (b["points"] || 0),
            0
          );
          return m;
        })
        .sort((m1, m2) => m2.Login - m1.Login)
        .sort((m1, m2) => m2.score - m1.score);
      let score = null;
      let rank = 0;
      for (let i in members) {
        let member = members[i];
        if (score == null || (score != null && member.score < score)) {
          score = member.score;
          rank++;
        }
        member.rank = rank;
      }
      return members;
    },
  },
};
</script>
